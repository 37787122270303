<template>
  <div style="text-align: left">
    <Card style="height:100%">
      <div class="search-con search-con-top">
        任务名 <Input clearable placeholder="输入任务名 搜索" class="search-input"
               v-model="queryinfo.name"
               @on-enter="namechange"
               @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;
        任务指令 <Input clearable placeholder="输入任务指令 搜索" class="search-input"
                   v-model="queryinfo.cmd"
                   @on-enter="namechange"
                   @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;
        定时器 <Input clearable placeholder="输入任务指令 搜索" class="search-input"
                    v-model="queryinfo.cron"
                    @on-enter="namechange"
                    @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;
        日志 <Input clearable placeholder="输入任务指令 搜索" class="search-input"
                   v-model="queryinfo.job_log"
                   @on-enter="namechange"
                   @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;
        状态
        <Select v-model="queryinfo.status" style="width:150px" clearable  @on-change="namechange">
          <Option v-for="item in statuslist" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
<!--        <DatePicker :value="dateValue" type="daterange" placement="bottom-end" @on-change="changeDate" placeholder="Select date" style=" margin-left: 2px; width: 200px"></DatePicker>-->
<!--        <Button @click="handleSearch" class="search-btn" type="primary">搜索</Button>-->
      </div>
      <Table size="small" ref="selection" :columns="columns" :data="tableData"></Table>
      <div style="margin: 10px;overflow: hidden">
        <div style="float: left;">
          <Page :total="count" :current="queryinfo.page" :page-size="queryinfo.pagesize"
                :page-size-opts=[10,15,25,35,50,100]
                show-sizer
                show-total
                @on-change="changePage"
                @on-page-size-change="handlePageSize"></Page>
        </div>
      </div>
      <Drawer title="日志信息" :closable="false" v-model="showlog" width="800">
        <div v-for="(item,index) in job_log" :key="index">
          <p>{{item}}</p>
        </div>
      </Drawer>
    </Card>
  </div>
</template>

<script>
import {get} from "@/api/http.js";
export default {
  data () {
    return {
      showlog: false,
      job_log: [],
      statuslist: [
        {value: "1",label:"成功"},
        {value: "2",label:"失败"},
      ],
      columns: [
        {type: 'selection', key: '', width: 60, align: 'center'},
        {title: 'job name', key: 'name'},
        {title: '状态', key: 'status',minWidth: 60,
          render: (h,{row}) =>{
            return h('Badge',this.get_status(row))
          }
        },
        {title: '定时器（秒 分 时 日 月 周）', key: 'cron',minWidth: 100},
        {title: '任务指令', key: 'cmd',minWidth: 250},
        {title: '执行时间', key: 'exec_time'},
        {title: '操 作', key: 'action', width: 230, align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showdetails(params.row)
                  }
                }
              }, '详 情'),
            ]);
          }
        }],
      dateValue: [],
      tableData: [],
      count: 0,
      queryinfo: {
        page: 1,
        pagesize: 10,
        name:"",
        status: null,
        cron: "",
        job_log:"",
        cmd: "",
      },
    }
  },
  methods: {
    getdata(queryinfo) {
      const url = "/cron/api/v1/cron/log"
      get(url,queryinfo)
          .then((resp)=>{
            this.tableData = resp["data"];
            this.count = resp.count
          }).catch(e=>{
        return e
      })
    },

    namechange() {
      this.queryinfo.name = this.queryinfo.name.trim()
      this.queryinfo.job_log = this.queryinfo.job_log.trim()
      this.queryinfo.cron = this.queryinfo.cron.trim()
      this.queryinfo.page = 1
      this.getdata(this.queryinfo)
    },

    showdetails(row) {
      this.showlog = true
      this.job_log = row["job_log"]
    },

    get_status(row){
      if (row["status"]===0) {
        return {props: {color: '#19be6b', text:  '成功'}}
      } else {
        return {props: {color: '#ed4014', text:  '失败'}}
      }
    },

    handleSearch () {
      this.getdata(this.queryinfo)
    },
    changePage (value) {
      this.queryinfo.page = value
      this.getdata(this.queryinfo)
    },
    handlePageSize (value) {
      this.queryinfo.pagesize = value
      this.getdata(this.queryinfo)
    },
  },

  created() {
    this.getdata(this.queryinfo)
  }
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;
  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }
    &-input {
      display: inline-block;
      width: 200px;
      margin-left: 2px;
    }
    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
